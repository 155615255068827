
<template>
  <CCard>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <AreaModal
      :modal.sync="modal"
      :AreaId="AreaId"
      :AreaItems="AreaItems"
      :edit="edit"
      :title="titleModal"
      @submited="handleSubmit"
      @close="closeModal"
    />
    <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.areaTypeList')}} </b>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol sm="12" class="d-flex justify-content-end">
            <CButton
              color="add"
              class="d-flex align-items-center"
              v-c-tooltip="{
                content: $t('label.areaType'),
                placement: 'top-end'
              }"
              @click="toggleAdd"
            >
              <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
            </CButton>
          </CCol>
          <CCol sm="12">
            <dataTableExtended
              class="align-center-row-datatable"
              :items="formatedItems"
              :fields="fields"
              column-filter
              :noItemsView="tableText.noItemsViewText"
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :items-per-page="tableText.itemsPerPage"
              hover
              small
              sorter
              pagination
            >
            <template #TpYardAreaIconRoute="{ index,item }">
                <td class="center-cell">
                    <img :src="srcImage(item.TpYardAreaIconRoute)" :alt="item.TpYardAreaId+index"  style="height: 35px !important; width: 35px !important;"  class="img-thumbnail icon" @error="setAltImg" />
                </td>
            </template>
              <template #Status="{ item }">
                <td class="text-center align-middle">
                  <CBadge :color="getBadge(item.Status)">
                    {{$t('label.'+item.Status)}}
                  </CBadge>
                </td>
              </template>
              <template #Detalle="{ item }">
                <td class="text-center">
                  <CButton
                    color="edit"
                    size="sm"
                    class="mr-1"
                    v-c-tooltip="{
                      content: $t('label.edit')+' '+$t('label.areaType'),
                      placement: 'top-end'
                    }"
                    @click="toggleEdit(item)"
                  >
                  <CIcon name="pencil" />
                </CButton>
                </td>
              </template>
            </dataTableExtended>
          </CCol>
        </CRow>
    </CCardBody>
  </CCard>
</template>
  
<script>
import { DateFormater } from "@/_helpers/funciones";
import General from "@/_mixins/general";
import { mapState } from "vuex";
import AreaModal from "./area-type-modal";



//DATA
function data() {
  return {
    items: [],
    Loading:  false,
    modal: false,
    AreaId: '',
    AreaItems: {},
    edit: false,
    titleModal: '',
    srcError:'/img/errorimage.jpg',
  };
}

//METHOD
function getListAreaType() {
  this.Loading = true;
  this.$http
    .get('TpYardArea-list',{ Filter: 'ACTIVO'})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        this.items = listado;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}

function toggleAdd() {
  this.modal = true;
  this.edit = false;
  this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.areaType');
}
function toggleEdit(item) {
  let _lang = this.$i18n.locale;
  this.AreaId = item.YardSubClasificationId;
  this.AreaItems = item;
  this.edit = true;
  this.modal = true;
  let title = _lang=='en' ? this.AreaItems.TpYardAreaNameEn : this.AreaItems.TpYardAreaNameEs;
  this.titleModal= this.$t('label.edit')+' '+this.$t('label.areaType')+': '+title;
}
function closeModal(){
  this.modal = false;
  this.edit = false;
  this.titleModal = '';
}
function handleSubmit() {
  this.getListAreaType();
}
function srcImage(icon){
  if(icon === null || icon === '' || icon === undefined){
    return this.srcError;
  }else{
    return `${this.$store.getters["connection/getBase"]}${icon}`;
  }
}
function setAltImg(event){
  event.target.src = this.srcError;
}
// COMPUTED
function fields() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      filter: false,
    },
    { key: "TpYardAreaNameEs", label: this.$t("label.areaType")+' '+('(ES)'),_styles: "width: 25%;",_classes:'text-center',},
    { key: "TpYardAreaNameEn", label: this.$t("label.areaType")+' '+('(EN)'),_styles: "width: 25%;",_classes:'text-center',},
    { key: "TpYardAreaIconRoute", label: this.$t("label.image"),_styles: "width: 5%;",_classes:'text-right',sorter: false,filter: false},
    { key: "Usuario", label: this.$t("label.user"),_styles: "width: 10%;",_classes:'text-center',},
    { key: "FormatedDate", label: this.$t("label.date"), _styles: "width: 10%;",_classes: "text-center" },
    { key: "Status", label: this.$t("label.status"),_styles: "width: 10%;", _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "min-width: 45px;",
    },
  ];
}
function formatedItems() {
  return this.items.map((items) =>
    Object.assign({}, items, {  
      Nro: items.Nro,
      TpYardAreaIconRoute: `${items.TpYardAreaIconRoute?items.TpYardAreaIconRoute.replace('public/', '', null, 'i'):''}`,
      Usuario: items.TransaLogin ? items.TransaLogin : "N/A",
      Status: items.Status,
      FormatedDate: items.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(items.TransaRegDate) : 'N/A',
      _classes: items.Status === "INACTIVO" ? "table-danger" : "",
    })
  );
}
function getYardId() {
  return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
export default {
  name: 'index',
  mixins: [General],
  props: {Tab: Number},
  data,
  components: {
    AreaModal,
  },
  mounted() {
    this.getListAreaType();
  },
  methods: {
    getListAreaType,
    toggleAdd,
    toggleEdit,
    handleSubmit,
    closeModal,
    srcImage,
    setAltImg,
  },
  computed: {
    fields,
    formatedItems,
    getYardId,
    ...mapState({
      activeTab: state => state.yard.tabIndex,
      yardSelected: state => state.yard.yardSelected
    }),
  },
};
</script>
<style lang="scss">
 .icon{
     background-color: grey; 
     color: black;
     border: 0px;
 }
 .center-cell {
     text-align: center;
 }
 .table-index {
     table {
         td {
         vertical-align: middle !important;
         }
     }
 }
</style>
